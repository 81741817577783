import {
  createSlice,
  createAsyncThunk,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { firestore } from "../firebase";

const initialState = {
  staffMemberListData: [],
  selectedStaffMember: null,
  showStaffForm: false,
  isLoading: false,
  error: null,
};
const middleware = [...getDefaultMiddleware(), thunkMiddleware];

export const staffMemberData = createSlice({
  name: "staffMemberData",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.staffMemberListData = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    toggleStaffForm: (state) => {
      state.showStaffForm = !state.showJobForm;
    },
    addStaffToList: (state, action) => {
      state.staffMemberListData.push(action.payload);
    },
    setSelectedStaffMember: (state, action) => {
      state.selectedStaffMember = action.payload;
    },
  },
  middleware,
});

export const {
  setData,
  setLoading,
  setError,
  toggleStaffForm,
  addStaffToList,
  setSelectedStaffMember,
} = staffMemberData.actions;

export const fetchStaffMemberData = () => async (dispatch, getState) => {
  const { staffData } = getState().combinedReducers;

  const querySnapshot = await getDocs(collection(firestore, "users"));

  let data = [];
  querySnapshot.forEach((doc) => {
    let tdata = doc.data();
    tdata.id = doc.id;

    if (tdata) data.push(tdata);
  });

  dispatch(setData(data));
};

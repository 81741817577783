import classes from "./Login.module.css";
import Button from "../Global/Button/Button.js";
import { loginWithGoogle } from "../../reducers/auth.reducer";
import { useDispatch } from "react-redux";

const Login = () => {
  const dispatch = useDispatch();

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>Login To AOK Tyres</h1>
      <div className={classes.buttonContainer}>
        <Button
          text="SIGN IN WITH GOOGLE"
          buttontype="link"
          colour="orange"
          type="button"
          onClick={() => dispatch(loginWithGoogle())}
        />
      </div>
    </div>
  );
};

export default Login;

import { createSlice } from '@reduxjs/toolkit';

export const menu = createSlice({
    name: 'marvel',
    initialState: {
        mobileMenuShow: false
    },
    reducers: {
        toggleMobileMenu: state => {
            state.mobileMenuShow = !state.mobileMenuShow;
        }
    }
});

export const menuActions = menu.actions;
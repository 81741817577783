import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import React from "react";
import { signInWithGoogle, signOut, firestore } from "../firebase";
import { collection, getDocs } from "firebase/firestore";

import Login from "../components/Login/Login.js";

const initialState = {
  currentUser: JSON.parse(localStorage.getItem("currentUser")) || null,
  currentUserData: JSON.parse(localStorage.getItem("currentUserData")) || null,
  userHasPermission:
    JSON.parse(localStorage.getItem("currentUserEnabled")) || false,
  isLoading: false,
  error: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
      localStorage.setItem("currentUser", JSON.stringify(action.payload));
    },
    setCurrentUserData: (state, action) => {
      state.currentUserData = action.payload;
      localStorage.setItem("currentUserData", JSON.stringify(action.payload));
    },
    setPermissionStatus: (state, action) => {
      state.userHasPermission = action.payload;
      localStorage.setItem(
        "currentUserEnabled",
        JSON.stringify(action.payload)
      );
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  setCurrentUser,
  setCurrentUserData,
  setPermissionStatus,
  setLoading,
  setError,
} = authSlice.actions;

export const loginWithGoogle = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const result = await signInWithGoogle();
    const user = result.user;
    dispatch(setCurrentUser(user));
    dispatch(setError(null));

    const fetchUserDataAndSetPermissions = async () => {
      const querySnapshot = await getDocs(collection(firestore, "users"));
      let emails = [],
        users = [];

      if (user) {
        querySnapshot.forEach((doc) => {
          emails.push(doc.data().email);
          users.push(doc.data());
        });

        if (emails.includes(user.email)) {
          const userData = users.find((obj) => obj["email"] === user.email);

          if (userData.enabled) {
            dispatch(setPermissionStatus(true));
            dispatch(setCurrentUserData(userData));
            return;
          } else {
            dispatch(setPermissionStatus(false));
            setCurrentUserData(null);
          }
        } else {
          dispatch(setPermissionStatus(false));
          setCurrentUserData(null);
        }
      }
    };
    fetchUserDataAndSetPermissions();
  } catch (error) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const logout = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    await signOut();
    dispatch(setCurrentUser(null));
    dispatch(setPermissionStatus(false));
    dispatch(setCurrentUserData(null));
    dispatch(setError(null));
  } catch (error) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const userNeedsPermission = (WrappedComponent, level) => {
  const WithAuthRedirect = (props) => {
    const { userHasPermission, currentUserData } = useSelector(
      (state) => state.combinedReducers.auth
    );

    // No permission so return the login screen
    if (!userHasPermission) {
      return <Login />;
    }

    // If we pass in a level then make sure the user has that level of access
    if (level && currentUserData.category !== level) return null;

    return <WrappedComponent {...props} />;
  };

  return WithAuthRedirect;
};

import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";

import { fetchAllTyreData } from "../../../reducers/tyre.reducer";
import { toggleJobTyreForm } from "../../../reducers/job.reducer";

import { ReactComponent as AddIcon } from "../../../assets/icons/square-plus-solid.svg";
import { ReactComponent as RemoveIcon } from "../../../assets/icons/trash-solid.svg";
import classes from "./JobFormTyreGrid.module.css";

const JobFormTyreGrid = ({ tyres, updateJobTyres }) => {
  const dispatch = useDispatch();
  const [jobTyreData, setJobTyreData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await dispatch(fetchAllTyreData(tyres));
      setJobTyreData(response);
    }

    if (tyres.length > 0) fetchData();
  }, []);

  const handleAddTyre = () => {
    dispatch(toggleJobTyreForm());
  };

  const handleDeleteTyre = (tyreToDelete) => {
    if (
      window.confirm(
        "This will remove the tyre from job are you sure? This WILL NOT update any stock levels."
      ) == true
    ) {
      const updatedTyres = tyres.filter((tyre) => tyre.id !== tyreToDelete);
      updateJobTyres(updatedTyres);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <h2 className={classes.title}>Items</h2>
        <AddIcon onClick={handleAddTyre} />
      </div>
      <div className={classes.items}>
        <div className={`${classes.item} ${classes.itemsHeader}`}>
          <span className={classes.size}>Size</span>
          <span className={classes.quantity}></span>
          <span className={classes.price}>Price</span>
          <span className={classes.cost}>Cost</span>
          <span className={classes.delete}></span>
        </div>
        {jobTyreData.map((fetchedTyre, index) => {
          const currentTyreOnJob = tyres.find((t) => t.id === fetchedTyre.id);
          const quantity = currentTyreOnJob.quantity;
          const cost = formatPrice(currentTyreOnJob.cost);
          const price = formatPrice(currentTyreOnJob.price);

          return (
            <div key={fetchedTyre.id} className={classes.item}>
              <span className={classes.size}>{fetchedTyre.size}</span>
              <span className={classes.quantity}>x {quantity}</span>
              <span className={classes.price}>{price}</span>
              <span className={classes.cost}>{cost}</span>
              <span className={classes.delete}>
                <RemoveIcon
                  className={classes.removeIcon}
                  onClick={() => handleDeleteTyre(fetchedTyre.id)}
                />
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default JobFormTyreGrid;

const formatPrice = (price) => {
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
  }).format(Number(price));
};

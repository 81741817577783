import { initializeApp } from 'firebase/app';
import { getFirestore, collection } from 'firebase/firestore';

import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyAbDdPcDYh6kT3BCho0p5LEVFrPMshjYvU",
  authDomain: "aoktyres-9840a.firebaseapp.com",
  projectId: "aoktyres-9840a",
  storageBucket: "aoktyres-9840a.appspot.com",
  messagingSenderId: "971299401640",
  appId: "1:971299401640:web:a85d537cf600d42ee8779d"
};

// Initialize Firebase app
export const app = initializeApp(firebaseConfig);

// Get a Firestore instance
export const firestore = getFirestore(app);

// Get the Firebase authentication instance
export const auth = getAuth();

export const signInWithGoogle = async () => {
  try {
    const provider = new GoogleAuthProvider();
    return await signInWithPopup(auth, provider);
  } catch (error) {
    console.error(error);
  }
};

export const signOut = async () => {
  await auth.signOut();
};
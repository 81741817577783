import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import {
  fetchStaffMemberData,
  toggleStaffForm,
  setSelectedStaffMember,
} from "../../../reducers/account.reducer";

import classes from "./AccountStaffMembers.module.css";

const AccountStaffMembers = () => {
  const dispatch = useDispatch();
  const { staffMemberListData } = useSelector(
    (state) => state.combinedReducers.staffMemberData
  );

  useEffect(() => {
    async function fetchData() {
      const response = await dispatch(fetchStaffMemberData(dispatch));
    }
    fetchData();
  }, [dispatch]);

  const handleStaffMemberClick = (staffData) => {
    dispatch(setSelectedStaffMember(staffData));
    dispatch(toggleStaffForm());
  };

  return (
    <>
      <h2 className={classes.title}>Staff Members</h2>
      <div className={classes.list}>
        {staffMemberListData
          ? staffMemberListData.map((staffData, index) => {
              return (
                <div
                  key={index}
                  className={classes.item}
                  onClick={() => handleStaffMemberClick(staffData)}
                >
                  <div className={classes.column}>
                    <span className={classes.mobileTitle}>Email:</span>
                    <span className={classes.value}>{staffData.email}</span>
                  </div>
                  <div className={classes.column}>
                    <span className={classes.mobileTitle}>Category:</span>
                    <span className={classes.value}>{staffData.category}</span>
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </>
  );
};

export default AccountStaffMembers;

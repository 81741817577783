import { createBrowserRouter, RouterProvider } from "react-router-dom";

// Import pages
import RootComponent from "./pages/Root";
import ErrorPage from "./pages/Error";
import HomePage from "./pages/Home/Home";
import TyreList from "./pages/TyreList/TyreList";
import JobList from "./pages/JobList/JobList";
import AccountPage from "./pages/Account/Account";
import DashboardPage from "./pages/Dashboard/Dashboard";

// createBrowserRouter Example - SIMPLE (PREFERED)
const router = createBrowserRouter([
  {
    // Define a ROOT component to house EVERYTHING
    path: "/", // Path of home
    element: <RootComponent />, // Component to display
    errorElement: <ErrorPage />, // Error Page to be shown
    children: [
      // Routes to child pages
      { index: true, element: <HomePage /> },
      { path: "/account", element: <AccountPage /> },
      { path: "/tyres/all", element: <TyreList /> },
      { path: "/job/all", element: <JobList /> },
      { path: "/dashboard", element: <DashboardPage /> },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;

import {
  createSlice,
  createAsyncThunk,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { firestore } from "../firebase";

const initialState = {
  tyreListData: [],
  showTyreForm: false,
  selectedTyre: null,
  searchCriteria: "",
  isLoading: false,
  error: null,
};
const middleware = [...getDefaultMiddleware(), thunkMiddleware];

export const tyreData = createSlice({
  name: "tyreData",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.tyreListData = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    toggleTyreForm: (state) => {
      state.showTyreForm = !state.showTyreForm;
    },
    addTyreToList: (state, action) => {
      state.tyreListData.push(action.payload);
    },
    setSelectedTyre: (state, action) => {
      state.selectedTyre = action.payload;
    },
    setSearchCriteria: (state, action) => {
      state.searchCriteria = action.payload;
    },
  },
  middleware,
});

export const {
  setData,
  setLoading,
  setError,
  toggleTyreForm,
  addTyreToList,
  setSelectedTyre,
  setSearchCriteria,
} = tyreData.actions;

export const fetchAllTyreData = (searchIDs) => async (dispatch, getState) => {
  const { tyreData } = getState().combinedReducers;
  const searchCriteria = tyreData.searchCriteria;
  const searchProperties = ["size"];

  const querySnapshot = await getDocs(collection(firestore, "tyres"));
  let data = [];
  querySnapshot.forEach((doc) => {
    let fitsCriteria = false,
      tdata = doc.data();
    tdata.id = doc.id;

    // If the id is passed in we need to search on that id
    if (searchIDs) {
      searchIDs.forEach((tyre) => {
        if (fitsCriteria) return;
        fitsCriteria = tdata.id === tyre.id ? true : false;
      });
    } else if (searchCriteria) {
      // Loop over each property of the object
      for (let propName of searchProperties) {
        // Check if the property value includes the search criteria
        let dataFound = tdata[propName]
          .toLowerCase()
          .includes(searchCriteria.toLowerCase());

        fitsCriteria = dataFound;
      }
    }

    // if a found isn't matched wipe the data for this item
    if (searchIDs || searchCriteria)
      fitsCriteria ? (tdata = tdata) : (tdata = null);

    if (tdata) data.push(tdata);
  });

  if (searchIDs) return data;

  dispatch(setData(data));
};

export const addOrUpdateTyre = createAsyncThunk(
  "tyreData/addOrUpdateTyre",
  async (tyreData) => {
    try {
      if (tyreData.id) {
        // If the tyre has an id, update it in the database
        await updateDoc(doc(firestore, "tyres", tyreData.id), tyreData);
      } else {
        // If the tyre doesn't have an id, add it to the database
        const docRef = await addDoc(collection(firestore, "tyres"), tyreData);
      }
      return true;
    } catch (error) {
      console.error("Error adding/updating document: ", error);
      return false;
    }
  }
);

export const deleteSelectedTyre = createAsyncThunk(
  "tyreData/deleteSelectedTyre",
  async (tyreData) => {
    try {
      if (tyreData.id) {
        // If the tyre has an id, update it in the database
        await deleteDoc(doc(firestore, "tyres", tyreData.id), tyreData);
      }
      return true;
    } catch (error) {
      console.error("Error adding/updating document: ", error);
      return false;
    }
  }
);

import { useDispatch, useSelector } from "react-redux";

import { toggleJobForm, setSelectedJob } from "../../../reducers/job.reducer";

import classes from "./JobListGrid.module.css";

const JobListGrid = () => {
  const dispatch = useDispatch();
  const { jobListData } = useSelector(
    (state) => state.combinedReducers.jobData
  );

  const handleJobClick = (jobListData) => {
    dispatch(setSelectedJob(jobListData));
    dispatch(toggleJobForm());
  };

  return (
    <div className={classes.list}>
      <div className={classes.tableHeader}>
        <span>REF</span>
        <span>REG</span>
        <span>Date</span>
        <span>Location</span>
        <span>Price</span>
      </div>
      {jobListData
        ? jobListData.map((jobData, index) => {
            const price = new Intl.NumberFormat("en-GB", {
              style: "currency",
              currency: "GBP",
            }).format(jobData.price);

            return (
              <div
                key={index}
                className={classes.item}
                onClick={() => handleJobClick(jobData)}
              >
                <div className={classes.column + " " + classes.ref}>
                  <span className={classes.value}>
                    {"AOK-" + jobData.id.slice(-4)}
                  </span>
                </div>
                <div className={classes.column}>
                  <span className={classes.mobileTitle}>Reg:</span>
                  <span className={classes.value}>{jobData.reg}</span>
                </div>
                <div className={classes.column}>
                  <span className={classes.mobileTitle}>Date:</span>
                  <span className={classes.value}>{jobData.date}</span>
                </div>
                <div className={classes.column}>
                  <span className={classes.mobileTitle}>Location:</span>
                  <span className={classes.value}>{jobData.location}</span>
                </div>
                <div className={classes.column}>
                  <span className={classes.mobileTitle}>Price:</span>
                  <span className={classes.value}>{price}</span>
                </div>
              </div>
            );
          })
        : null}
    </div>
  );
};

export default JobListGrid;

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import classes from "./Home.module.css";

import { ReactComponent as ShelvesIcon } from "../../assets/icons/shelves-duotone.svg";
import { ReactComponent as FlatTyreIcon } from "../../assets/icons/tire-flat-duotone.svg";
import { ReactComponent as GraphIcon } from "../../assets/icons/chart-line-up-duotone.svg";

import { userNeedsPermission } from "../../reducers/auth.reducer";

const HomePage = () => {
  const { currentUser, currentUserData } = useSelector(
    (state) => state.combinedReducers.auth
  );

  // ToDo:: Create a dashboard

  const HomeComponent = userNeedsPermission(() => (
    <>
      {currentUser ? (
        <div className={classes.itemContainer}>
          {/* {currentUserData.category == "admin" ? (
            <Link to={`/dashboard`} className={classes.item}>
              <GraphIcon className={classes.icon} />
              <h2 className={classes.text}>Stats</h2>
            </Link>
          ) : (
            ""
          )} */}
          <Link to={`/tyres/all`} className={classes.item}>
            <ShelvesIcon className={classes.icon} />
            <h2 className={classes.text}>Inventory</h2>
          </Link>
          <Link to={`/job/all`} className={classes.item}>
            <FlatTyreIcon className={classes.icon} />
            <h2 className={classes.text}>Jobs</h2>
          </Link>
        </div>
      ) : null}
    </>
  ));

  return (
    <>
      <div className={classes.container}>
        {/* <Intro /> */}
        <HomeComponent />
      </div>
    </>
  );
};

export default HomePage;

import MainNavigation from "../components/MainNavigation/MainNavigation";

const ErrorPage = () => {
    return (
        <>
            <MainNavigation />
            <main >
                <h1>An Error Occurred!</h1>
                <p>Could Not Find This Page</p>
            </main>
        </>
    )
}

 export default ErrorPage;
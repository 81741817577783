import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";

import { setSearchCriteria } from "../../../reducers/tyre.reducer";

import classes from "./TyreSearchBar.module.css";

import { fetchAllTyreData } from "../../../reducers/tyre.reducer";

const TyreSearchBar = () => {
  const dispatch = useDispatch();
  const { searchCriteria } = useSelector(
    (state) => state.combinedReducers.tyreData
  );

  const inputRef = useRef("");

  const handleSearch = (searchTerm) => {
    dispatch(setSearchCriteria(searchTerm));
    async function fetchData() {
      const response = await dispatch(fetchAllTyreData());
    }
    fetchData();
  };

  useEffect(() => {
    inputRef.current.focus();
  }, [searchCriteria]);

  return (
    <input
      type="text"
      placeholder="SEARCH"
      onChange={(e) => handleSearch(e.target.value)}
      className={classes.input}
      value={searchCriteria ? searchCriteria : ""}
      ref={inputRef}
    />
  );
};

export default TyreSearchBar;

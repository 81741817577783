import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { userNeedsPermission } from "../../reducers/auth.reducer.js";
import {
  toggleTyreForm,
  setSelectedTyre,
  fetchAllTyreData,
} from "../../reducers/tyre.reducer";

import TyreForm from "../../components/Tyres/TyreForm/TyreForm.js";
import TyreListGrid from "../../components/Tyres/TyreListGrid/TyreListGrid.js";
import TyreSearchBar from "../../components/Tyres/TyreSearchBar/TyreSearchBar.js";
import Button from "../../components/Global/Button/Button.js";

import classes from "./TyreList.module.css";

const TyreList = () => {
  const dispatch = useDispatch();
  const { showTyreForm, selectedTyre } = useSelector(
    (state) => state.combinedReducers.tyreData
  );

  const { currentUser } = useSelector((state) => state.combinedReducers.auth);

  // Get tyre data on page load
  useEffect(() => {
    async function fetchData() {
      const response = await dispatch(fetchAllTyreData());
    }
    fetchData();
  }, [dispatch]);

  // Manage background scroll when modal is open
  useEffect(() => {
    if (showTyreForm) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showTyreForm]);

  const handleAddNewItem = () => {
    dispatch(setSelectedTyre(null));
    dispatch(toggleTyreForm());
  };

  const TyreListComponent = userNeedsPermission(() =>
    currentUser ? (
      <>
        <div className={classes.header}>
          <h1>AOK Tyre Inventory</h1>
          <TyreSearchBar />
          <div className={classes.headerButtonContainer}>
            <Button
              text="ADD NEW TYRE"
              buttontype="link"
              colour="orange"
              type="button"
              onClick={handleAddNewItem}
            />
          </div>
        </div>
        <TyreListGrid />
        {showTyreForm && <TyreForm selectedTyre={selectedTyre} />}
      </>
    ) : null
  );

  return (
    <>
      <div className={classes.outerContainer}>
        <TyreListComponent />
      </div>
    </>
  );
};

export default TyreList;

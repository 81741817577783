import { useDispatch, useSelector } from "react-redux";
import {
  addOrUpdateTyre,
  toggleTyreForm,
  fetchAllTyreData,
} from "../../../reducers/tyre.reducer";
import { useState } from "react";
import classes from "./TyreForm.module.css";

import Button from "../../Global/Button/Button.js";
import CustomField from "../../Global/CustomField/CustomField";

const TyreForm = ({ selectedTyre }) => {
  const dispatch = useDispatch();

  const [id, setID] = useState(selectedTyre?.id || "");
  const [size, setSize] = useState(selectedTyre?.size || "");
  const [brand, setBrand] = useState(selectedTyre?.brand || "");
  const [manageStock, setManageStock] = useState(
    selectedTyre?.manageStock || "NO"
  );
  const [quantity, setQuantity] = useState(selectedTyre?.quantity || 0);
  const [location, setLocation] = useState(selectedTyre?.location || "");
  const [cost, setCost] = useState(selectedTyre?.cost || "");
  const [errors, setErrors] = useState({
    size: false,
    cost: false,
    quantity: false,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newTyre = { size, brand, manageStock, quantity, location, cost, id };

    // Ensure all fields are filled in
    if (!size || !cost || (manageStock === "YES" && !quantity)) {
      // Loop through mandatory inputs and check them
      const stateMap = {
        size: size,
        cost: cost,
        quantity: quantity,
      };

      let newErrors = [];
      document
        .querySelectorAll('#tyre-form input[mandatory="true"]')
        .forEach((input) => {
          newErrors = {
            ...newErrors,
            [input.id]: !stateMap[input.id],
          };
        });

      setErrors(newErrors);
    } else {
      // Add my new tyre
      const result = await dispatch(addOrUpdateTyre(newTyre));

      if (result.payload) {
        // Refresh the data
        dispatch(fetchAllTyreData());
        // Hide the tyre form
        dispatch(toggleTyreForm());
        // Clear fields for next tyre input
        setID("");
        setSize("");
        setBrand("");
        setManageStock("NO");
        setQuantity(0);
        setLocation("");
        setCost("");
      }
    }
  };

  return (
    <div className={classes.container}>
      <form id="tyre-form" className={classes.form} onSubmit={handleSubmit}>
        <CustomField
          caption="Size"
          type="text"
          value={size}
          onChange={(newValue) => setSize(newValue)}
          mandatory="true"
          haserror={errors.size}
        />
        <CustomField
          caption="Brand"
          type="text"
          value={brand}
          onChange={(newValue) => setBrand(newValue)}
        />
        <CustomField
          caption="Manage Stock?"
          type="select"
          options={["YES", "NO"]}
          value={manageStock}
          onChange={(newValue) => setManageStock(newValue)}
          mandatory="true"
        />
        {manageStock === "YES" ? (
          <CustomField
            caption="Quantity"
            type="number"
            value={quantity}
            onChange={(newValue) => setQuantity(newValue)}
            mandatory="true"
            haserror={errors.quantity}
          />
        ) : (
          ""
        )}
        <CustomField
          caption="Location"
          type="text"
          value={location}
          onChange={(newValue) => setLocation(newValue)}
        />
        <CustomField
          caption="Cost"
          type="number"
          value={cost}
          onChange={(newValue) => setCost(newValue)}
          mandatory="true"
          haserror={errors.cost}
        />
        <div className={classes.buttonContainer}>
          <Button
            text="CANCEL"
            colour="red"
            type="button"
            onClick={() => dispatch(toggleTyreForm())}
          />
          <Button text="SAVE" colour="orange" type="submit" />
        </div>
      </form>
    </div>
  );
};

export default TyreForm;

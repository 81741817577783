import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";

import {
  setSearchCriteria,
  fetchAllJobData,
} from "../../../reducers/job.reducer";

import Dropdown from "../../Global/CustomField/Dropdown/Dropdown";

import classes from "./JobSearchBar.module.css";

const JobSearchBar = () => {
  const dispatch = useDispatch();
  const { searchCriteria } = useSelector(
    (state) => state.combinedReducers.jobData
  );
  const [searchData, setSearchData] = useState(
    searchCriteria || { term: "", dateRange: "today" }
  );

  const inputRef = useRef(null);

  const handleSearchTermInput = (searchTerm) => {
    setSearchData({ ...searchData, term: searchTerm });
  };

  const handleDateRangeChange = (dateRange) => {
    setSearchData({ ...searchData, dateRange: dateRange });
  };

  useEffect(() => {
    if (
      searchData.term !== searchCriteria.term ||
      searchData.dateRange !== searchCriteria.dateRange
    ) {
      console.log("run search");

      // Check if searchData has actually changed
      dispatch(setSearchCriteria(searchData));
      async function fetchData() {
        const response = await dispatch(fetchAllJobData(dispatch));
      }
      fetchData();
    }

    if (searchData.term) inputRef.current.focus();
  }, [searchData]);

  return (
    <div className={classes.container}>
      <input
        type="text"
        placeholder="SEARCH"
        onChange={(e) => handleSearchTermInput(e.target.value)}
        className={classes.input}
        value={searchData.term ? searchData.term : ""}
        ref={inputRef}
      />
      <Dropdown
        name="date"
        options={[
          { name: "Today", value: "today" },
          { name: "All Time", value: "allTime" },
        ]}
        defaultOption={searchData.dateRange}
        onChange={(e) => handleDateRangeChange(e.target.value)}
      />
    </div>
  );
};

export default JobSearchBar;

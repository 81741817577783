import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import TyreListGrid from "../../Tyres/TyreListGrid/TyreListGrid";
import TyreSearchBar from "../../Tyres/TyreSearchBar/TyreSearchBar";
import CustomField from "../../Global/CustomField/CustomField";
import Button from "../../Global/Button/Button.js";

import classes from "./JobFormAddTyre.module.css";

import {
  toggleJobForm,
  toggleJobTyreForm,
} from "../../../reducers/job.reducer";

import { fetchAllTyreData } from "../../../reducers/tyre.reducer";

const JobFormAddTyre = ({ tyres, updateJobTyres }) => {
  const dispatch = useDispatch();
  const [selectedTyre, setSelectedTyre] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await dispatch(fetchAllTyreData());
    }
    fetchData();
  }, [dispatch]);

  const selectTyre = (tyreData) => {
    if (tyreData.manageStock === "YES" && tyreData.quantity <= 0) {
      alert("This tyre is out of stock, please manage stock first");
    } else {
      setSelectedTyre(tyreData);
    }
  };

  const handleAddTyre = (e) => {
    e.preventDefault();

    let tyreDataToAdd = {};
    tyreDataToAdd.id = selectedTyre.id;
    tyreDataToAdd.quantity = quantity;
    tyreDataToAdd.cost = selectedTyre.cost * quantity;
    tyreDataToAdd.price = price * quantity;

    let updatedTyres = [...tyres];

    // Check if the tyre already exists in the array and update the quantity if it does
    const tyreIndex = updatedTyres.findIndex(
      (tyre) => tyre.id === tyreDataToAdd.id
    );

    if (tyreIndex !== -1) {
      // // Check if the total quantity is greater than the stock
      // if (
      //   Number(updatedTyres[tyreIndex].quantity) +
      //     Number(tyreDataToAdd.quantity) >
      //   Number(selectedTyre.quantity)
      // ) {
      //   alert(
      //     "You cannot add more than what is in stock, you only have " +
      //       selectedTyre.quantity +
      //       " left in stock."
      //   );
      //   return;
      // }

      const newQuantity =
        Number(updatedTyres[tyreIndex].quantity) +
        Number(tyreDataToAdd.quantity);

      const newCost =
        Number(updatedTyres[tyreIndex].cost) + Number(tyreDataToAdd.cost);

      const newPrice =
        Number(updatedTyres[tyreIndex].price) + Number(tyreDataToAdd.price);

      updatedTyres[tyreIndex] = {
        ...updatedTyres[tyreIndex],
        quantity: newQuantity,
        price: newPrice,
        cost: newCost,
      };
    }
    if (selectedTyre.manageStock === "YES")
      if (Number(tyreDataToAdd.quantity) > Number(selectedTyre.quantity)) {
        // Check if the quantity is greater than the stock
        alert("You cannot add more than what is in stock");
        return;
      }

    if (tyreIndex === -1) updatedTyres.push(tyreDataToAdd); // Add the new tyre to the array

    updateJobTyres(updatedTyres);
    dispatch(toggleJobTyreForm());
  };

  const handleCancelClick = () => {
    setSelectedTyre({});
    setQuantity(0);
    dispatch(toggleJobTyreForm());
    dispatch(toggleJobForm());
  };

  return (
    <>
      {!selectedTyre.id ? (
        <div className={classes.tyreList}>
          <TyreSearchBar />
          <TyreListGrid
            onClickOverride={(tyreData) => selectTyre(tyreData)}
            limit={5}
          />
          <Button
            text="Cancel"
            colour="red"
            type="button"
            onClick={handleCancelClick}
            style={{ marginTop: "1rem", width: "100%" }}
          />
        </div>
      ) : (
        <form className={classes.form} onSubmit={handleAddTyre}>
          <div className={classes.fields}>
            <CustomField
              caption="Quantity"
              type="number"
              value={quantity}
              onChange={(newValue) => setQuantity(newValue)}
              min="1"
            />
            <CustomField
              caption="Price (for one item)"
              value={price}
              onChange={(newValue) => setPrice(newValue)}
              min="0"
            />
          </div>
          <span className={classes.warning}>
            Warning: Adding this item will reduce its stock
          </span>
          <Button
            text="Cancel"
            colour="red"
            type="button"
            onClick={handleCancelClick}
          />
          <Button text="Add Tyre" colour="orange" type="submit" />
        </form>
      )}
    </>
  );
};

export default JobFormAddTyre;

import { combineReducers } from "redux";

// Reducers
import { menu } from "./menu.reducer";
import { authSlice } from "./auth.reducer.js";
import { tyreData } from "./tyre.reducer";
import { jobData } from "./job.reducer";
import { staffMemberData } from "./account.reducer";

export const combinedReducers = combineReducers({
  menu: menu.reducer,
  auth: authSlice.reducer,
  tyreData: tyreData.reducer,
  jobData: jobData.reducer,
  staffMemberData: staffMemberData.reducer,
});

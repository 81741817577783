import {
  createSlice,
  createAsyncThunk,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { firestore } from "../firebase";

const initialState = {
  jobListData: [],
  selectedJob: null,
  showJobForm: false,
  showJobTyreForm: false,
  searchCriteria: { term: "", dateRange: "today" },
  isLoading: false,
  error: null,
};
const middleware = [...getDefaultMiddleware(), thunkMiddleware];

export const jobData = createSlice({
  name: "jobData",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.jobListData = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    toggleJobForm: (state) => {
      state.showJobForm = !state.showJobForm;
    },
    toggleJobTyreForm: (state) => {
      state.showJobTyreForm = !state.showJobTyreForm;
    },
    addJobToList: (state, action) => {
      state.jobListData.push(action.payload);
    },
    setSelectedJob: (state, action) => {
      state.selectedJob = action.payload;
    },
    setSearchCriteria: (state, action) => {
      state.searchCriteria = action.payload;
    },
  },
  middleware,
});

export const {
  setData,
  setLoading,
  setError,
  toggleJobForm,
  toggleJobTyreForm,
  addJobToList,
  setSelectedJob,
  setSearchCriteria,
} = jobData.actions;

export const fetchAllJobData = () => async (dispatch, getState) => {
  const { jobData } = getState().combinedReducers;
  const searchCriteria = jobData.searchCriteria;
  const searchProperties = ["date", "reg", "location", "id"];

  const querySnapshot = await getDocs(collection(firestore, "jobs"));
  let data = [];
  querySnapshot.forEach((doc) => {
    let tdata = doc.data();
    tdata.id = doc.id;

    // Check for the date filter
    if (searchCriteria.dateRange === "today") {
      let fitsCriteria = false;

      if (tdata.date === getTodaysDate()) fitsCriteria = true;

      // if a found isn't matched wipe the data for this item
      fitsCriteria ? (tdata = tdata) : (tdata = null);
    }

    // Now set any search filters if neccessary
    if (tdata != null && searchCriteria.term) {
      let fitsCriteria = false;
      // Loop over each property of the object
      for (let propName of searchProperties) {
        console.log(propName, tdata[propName]);

        // Check if the property value includes the search criteria
        let dataFound = tdata[propName]
          .toLowerCase()
          .includes(searchCriteria.term.toLowerCase());

        if (dataFound) fitsCriteria = true;
      }

      // if a found isn't matched wipe the data for this item
      fitsCriteria ? (tdata = tdata) : (tdata = null);
    }

    if (tdata) data.push(tdata);
  });

  // Sort the by name
  data.sort((a, b) => {
    const dateTimeA = new Date(`${a.date} ${a.time}`);
    const dateTimeB = new Date(`${b.date} ${b.time}`);
    return dateTimeB - dateTimeA;
  });

  dispatch(setData(data));
};

export const addOrUpdateJob = createAsyncThunk(
  "jobData/addOrUpdateJob",
  async (jobData) => {
    try {
      if (jobData.id) {
        // Get the old job data from the database
        const docRef = doc(firestore, "jobs", jobData.id);
        const docSnap = await getDoc(docRef);
        const currentJobData = docSnap.data();

        // Loop tyres in new data and compare with old job data
        for (let tyreInNewJob of jobData.tyres) {
          // Get the tyre data from the db for later
          const docRef = doc(firestore, "tyres", tyreInNewJob.id);
          const docSnap = await getDoc(docRef);
          const tyreData = docSnap.data();

          // Make sure the manage stock flag is set before we do anything
          if (tyreData.manageStock === "YES") {
            // Find the old tyre in the new job data
            const tyreInOldJob = currentJobData.tyres.find(
              (t) => t.id === tyreInNewJob.id
            );

            // Check if the quantity is different on the old and new job
            if (tyreInOldJob) {
              if (tyreInOldJob.quantity < tyreInNewJob.quantity) {
                tyreData.quantity =
                  tyreData.quantity -
                  (tyreInNewJob.quantity - tyreInOldJob.quantity);
              }
            } else {
              tyreData.quantity = tyreData.quantity - tyreInNewJob.quantity;
            }

            // Reduce the stock
            await updateDoc(doc(firestore, "tyres", tyreInNewJob.id), tyreData);
          }
        }

        // Update it in the database
        await updateDoc(doc(firestore, "jobs", jobData.id), jobData);
      } else {
        // If the job doesn't have an id, add it to the database
        const docRef = await addDoc(collection(firestore, "jobs"), jobData);

        // Now reduce the stock of the tyres
        for (let tyre of jobData.tyres) {
          const docRef = doc(firestore, "tyres", tyre.id);
          const docSnap = await getDoc(docRef);
          const tyreData = docSnap.data();

          if (tyreData.manageStock === "YES") {
            tyreData.quantity = tyreData.quantity - tyre.quantity;
            await updateDoc(doc(firestore, "tyres", tyre.id), tyreData);
          }
        }

        // Set the id as the one from the database
        jobData.id = docRef.id;
      }

      return jobData;
    } catch (error) {
      console.error("Error adding/updating document: ", error);
      return false;
    }
  }
);

export const deleteJob = createAsyncThunk(
  "jobData/deleteJob",
  async (jobData) => {
    try {
      if (jobData.id) {
        // If the job has an id, update it in the database
        await deleteDoc(doc(firestore, "jobs", jobData.id), jobData);
      }
      return true;
    } catch (error) {
      console.error("Error adding/updating document: ", error);
      return false;
    }
  }
);

const getCorrectDateFormat = (date) => {
  const year = date.getFullYear();

  // Get the month, add 1 because months are zero-indexed
  const month = (date.getMonth() + 1).toString().padStart(2, "0");

  // Get the day of the month
  const day = date.getDate().toString().padStart(2, "0");

  // Combine the components into the desired format
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

export const getTodaysDate = () => {
  // This is here temopreoarly to get todays date in the correct format
  // for when the date field is empty
  const today = new Date();
  return getCorrectDateFormat(today);
};

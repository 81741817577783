import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { menuActions } from "../../reducers/menu.reducer";
import { logout } from "../../reducers/auth.reducer";

import classes from "./MainNavigation.module.css";
import logo from "../../assets/logo.jpg";
import { ReactComponent as MobileMenu } from "../../assets/icons/bars-solid.svg";
import { ReactComponent as HomeIcon } from "../../assets/icons/house-duotone.svg";
import { ReactComponent as ShelvesIcon } from "../../assets/icons/shelves-duotone.svg";
import { ReactComponent as FlatTyreIcon } from "../../assets/icons/tire-flat-duotone.svg";
import { ReactComponent as AccountIcon } from "../../assets/icons/circle-user-duotone.svg";
import { ReactComponent as LogOutIcon } from "../../assets/icons/right-from-bracket-duotone.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/rectangle-xmark-duotone.svg";

const tel = "07495 153112";
const email = "aoktyresuk@gmail.com";

const MainNavigation = () => {
  const dispatch = useDispatch();
  const { mobileMenuShow } = useSelector(
    (state) => state.combinedReducers.menu
  );
  const { currentUser, userHasPermission } = useSelector(
    (state) => state.combinedReducers.auth
  );

  // Manage background scroll when menu is open
  useEffect(() => {
    if (mobileMenuShow) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [mobileMenuShow]);

  const toggleMobileMenuHandler = () => {
    // Show the characterInfoPanel
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      dispatch(menuActions.toggleMobileMenu());
    }
  };

  const mobileMenuIsOpen = () => {
    if (mobileMenuShow) return classes.mobileMenuActive;

    return "";
  };

  const handleLogout = () => {
    dispatch(logout());
    toggleMobileMenuHandler();
  };

  const isLoggedIn = userHasPermission;

  return (
    <header className={classes.header}>
      <div className={classes.innerDiv}>
        {isLoggedIn ? (
          <MobileMenu
            id="mob-menu"
            className={classes.mobileMenu}
            onClick={toggleMobileMenuHandler}
          />
        ) : (
          ""
        )}
        <NavLink to="/" className={classes.logoContainer}>
          <img className={classes.logo} src={logo} alt="A-OK Logo" />
        </NavLink>
        {isLoggedIn ? (
          <div className={`${classes.list} ${mobileMenuIsOpen()}`}>
            <NavLink
              to="/"
              className={`${classes.link} ${({ isActive }) =>
                isActive ? "active" : undefined}`}
              end
              onClick={toggleMobileMenuHandler}
              title="Home"
            >
              <HomeIcon className={classes.icon} />
              <span className={classes.text}>HOME</span>
            </NavLink>
            <NavLink
              to="/tyres/all"
              className={`${classes.link} ${({ isActive }) =>
                isActive ? "active" : undefined}`}
              onClick={toggleMobileMenuHandler}
              title="Inventory"
            >
              <ShelvesIcon className={classes.icon} />
              <span className={classes.text}>INVENTORY</span>
            </NavLink>
            <NavLink
              to="/job/all"
              className={`${classes.link} ${({ isActive }) =>
                isActive ? "active" : undefined}`}
              end
              onClick={toggleMobileMenuHandler}
              title="Jobs"
            >
              <FlatTyreIcon className={classes.icon} />
              <span className={classes.text}>JOBS</span>
            </NavLink>
            <span className={classes.mobileSplitter}></span>
            <NavLink
              to="/account"
              className={`${classes.link} ${({ isActive }) =>
                isActive ? "active" : undefined}`}
              end
              onClick={toggleMobileMenuHandler}
              title="Account"
            >
              <AccountIcon className={classes.icon} />
              <span className={classes.text}>ACCOUNT</span>
            </NavLink>
            <span
              onClick={handleLogout}
              className={`${classes.link} ${classes.red}`}
            >
              <LogOutIcon className={classes.icon} />
              <span className={classes.text}>SIGN OUT</span>
            </span>
            <span className={classes.mobileSplitter}></span>
            <span
              className={`${classes.link} ${classes.closeMobileMenu}`}
              onClick={toggleMobileMenuHandler}
            >
              <CloseIcon className={classes.icon} />
              <span className={classes.text}>CLOSE MENU</span>
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
    </header>
  );
};

export default MainNavigation;

import classes from "./CustomField.module.css";

const CustomField = ({
  id,
  caption,
  type,
  value,
  onChange,
  hint,
  haserror,
  errormessage,
  ...props
}) => {
  const fieldID =
    id || caption.toLowerCase().replace(" ", "-").replace("?", "");
  const formatDateTime = (dateTimeString) => {
    if (type === "date") {
      const dateObj = new Date(dateTimeString);
      const year = dateObj.getFullYear();
      let month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      let day = dateObj.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    } else if (type === "time") {
      const [hours, minutes] = dateTimeString.split(":");
      const formattedTime = `${hours}:${minutes}`;
      return formattedTime;
    } else {
      return value;
    }
  };

  const handleChange = (e) => {
    if (type === "date" || type === "time") {
      const dateValue = e.target.value;
      onChange(dateValue);
    } else {
      const fieldValue = e.target.value;
      if (value !== undefined) {
        onChange(fieldValue);
      }
    }
  };

  // Handle select boxes
  if (type === "select") {
    return (
      <div className={classes.field}>
        <label>{caption}</label>
        <select
          className={haserror ? classes.error : ""}
          id={fieldID}
          type="select"
          value={formatDateTime(value)}
          onChange={handleChange}
          {...props}
        >
          {props.options.map((option, index) => {
            return (
              <option key={index} value={option}>
                {option}
              </option>
            );
          })}
        </select>
        {haserror ? (
          <span className={classes.errorMessage}>{errormessage}</span>
        ) : (
          ""
        )}
      </div>
    );
  }

  return (
    <div className={classes.field}>
      <label>{caption}</label>
      <input
        id={fieldID}
        type={type}
        value={formatDateTime(value)}
        onChange={handleChange}
        {...props}
        className={haserror ? classes.error : ""}
      />
      {haserror && errormessage ? (
        <span className={classes.errorMessage}>{errormessage}</span>
      ) : (
        ""
      )}
    </div>
  );
};

export default CustomField;

import { useSelector } from "react-redux";
import { useState } from "react";

import { userNeedsPermission } from "../../reducers/auth.reducer";

import AccountDetails from "../../components/Account/AccountDetails/AccountDetails";
import AccountStaffMembers from "../../components/Account/AccountStaffMembers/AccountStaffMembers";
import classes from "./Account.module.css";

const AccountPage = () => {
  const { currentUser, currentUserData } = useSelector(
    (state) => state.combinedReducers.auth
  );
  const [currentPage, setCurrentPage] = useState("Account Details");

  const showAccountSection = (currentPage) => {
    switch (currentPage) {
      case "Account Details":
        return <AccountDetails />;
      case "Staff":
        return <AccountStaffMembers />;
      default:
        return <AccountDetails />;
    }
  };

  const handleAccountSectionChange = (section) => {
    setCurrentPage(section);
  };

  const AccountComponent = userNeedsPermission(() => (
    <div className={classes.outerContainer}>
      {currentUserData.category == "admin" ? (
        <div className={classes.menuContainer}>
          <span
            className={classes.menuItem}
            onClick={() => handleAccountSectionChange("Account Details")}
          >
            Account Details
          </span>
          <span
            className={classes.menuItem}
            onClick={() => handleAccountSectionChange("Staff")}
          >
            Staff Members
          </span>
        </div>
      ) : null}
      <div className={classes.displayContainer}>
        {showAccountSection(currentPage)}
      </div>
    </div>
  ));

  return (
    <>
      <div className={classes.container}>
        {/* <Intro /> */}
        <AccountComponent />
      </div>
    </>
  );
};

export default AccountPage;

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { userNeedsPermission } from "../../reducers/auth.reducer.js";
import {
  fetchAllJobData,
  setSelectedJob,
  toggleJobForm,
  toggleJobTyreForm,
} from "../../reducers/job.reducer.js";

import JobListGrid from "../../components/Jobs/JobListGrid/JobListGrid.js";
import JobSearchBar from "../../components/Jobs/JobSearchBar/JobSearchBar.js";
import JobForm from "../../components/Jobs/JobForm/JobForm.js";
import Button from "../../components/Global/Button/Button.js";

import classes from "./JobList.module.css";

const JobList = () => {
  const dispatch = useDispatch();
  const { selectedJob, showJobForm } = useSelector(
    (state) => state.combinedReducers.jobData
  );

  const { currentUser } = useSelector((state) => state.combinedReducers.auth);

  // Get Job data on page load
  useEffect(() => {
    async function fetchData() {
      const response = await dispatch(fetchAllJobData(dispatch));
    }
    fetchData();
  }, [dispatch]);

  // Manage background scroll when modal is open
  useEffect(() => {
    if (showJobForm) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showJobForm]);

  const handleAddNewItem = () => {
    dispatch(setSelectedJob(null));
    dispatch(toggleJobForm());
    dispatch(toggleJobTyreForm());
  };

  const JobListComponent = userNeedsPermission(() =>
    currentUser ? (
      <>
        <div className={classes.header}>
          <h1>All Jobs</h1>
          <JobSearchBar />
          <div className={classes.headerButtonContainer}>
            <Button
              text="ADD NEW JOB"
              buttontype="link"
              colour="orange"
              type="button"
              onClick={handleAddNewItem}
            />
          </div>
        </div>
        <JobListGrid />
        {showJobForm && <JobForm selectedJob={selectedJob} />}
      </>
    ) : null
  );

  return (
    <>
      <div className={classes.outerContainer}>
        <JobListComponent />
      </div>
    </>
  );
};

export default JobList;

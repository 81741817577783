import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllTyreData,
  toggleTyreForm,
  setSelectedTyre,
  deleteSelectedTyre,
} from "../../../reducers/tyre.reducer";

import classes from "./TyreListGrid.module.css";
import { ReactComponent as BinIcon } from "../../../assets/icons/trash-solid.svg";

const TyreListGrid = ({ onClickOverride, limit }) => {
  const dispatch = useDispatch();
  const { tyreListData } = useSelector(
    (state) => state.combinedReducers.tyreData
  );

  const handleTyreClick = (tyreData) => {
    if (onClickOverride) {
      onClickOverride(tyreData);
    } else {
      dispatch(setSelectedTyre(tyreData));
      dispatch(toggleTyreForm());
    }
  };

  const handleDeleteClick = (tyreData) => {
    if (
      window.confirm(
        `Are you sure you want to delete the record for ${tyreData.size}? This tyre might be in use in on a job.`
      )
    ) {
      dispatch(deleteSelectedTyre(tyreData));
      dispatch(fetchAllTyreData());
    }

    // Toggle the form so it doesnt open from the click on the row
    dispatch(toggleTyreForm());
  };

  // if (limit) tyreListData = tyreListData.slice(0, limit);

  return (
    <div className={classes.list}>
      <div className={classes.tableHeader}>
        <span>Size</span>
        <span>Brand</span>
        <span id="empty"></span>
      </div>
      {tyreListData
        ? tyreListData.map((tyreData, index) => {
            return (
              <div
                key={index}
                className={classes.item}
                onClick={() => handleTyreClick(tyreData)}
              >
                <div className={classes.column}>
                  <span className={classes.mobileTitle}>Size:</span>
                  <span className={classes.value}>{tyreData.size}</span>
                </div>
                <div className={classes.column}>
                  <span className={classes.mobileTitle}>Brand:</span>
                  <span className={classes.value}>{tyreData.brand}</span>
                </div>
                <div className={classes.column}>
                  <span className={classes.mobileTitle}>Delete:</span>
                  <a onClick={() => handleDeleteClick(tyreData)}>
                    <BinIcon className={classes.delete} />
                  </a>
                </div>
              </div>
            );
          })
        : null}
    </div>
  );
};

export default TyreListGrid;

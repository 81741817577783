import classes from './Button.module.css'

const Button = (props) => {

    const getColourClass = () => {
        const colour = props.colour;

        switch (colour) {
          case 'orange':
            return classes.orange;
          case 'red':
            return classes.red;
          default:
            return classes.orange;
        }
    }

    return (
      <>
        {props.buttontype === 'link' ? (
          <a
            className={`${classes.button} ${getColourClass()}`}
            {...props}
          >
            {props.text}
          </a>
        ) : (
          <button
            className={`${classes.button} ${getColourClass()}`}
            {...props}
          >
            {props.text}
          </button>
        )}
      </>
    );
  };

export default Button;
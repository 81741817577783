import { useSelector } from "react-redux";
import { useState } from "react";

import classes from "./AccountDetails.module.css";
import Button from "../../Global/Button/Button";

const AccountDetails = () => {
  const { currentUser, currentUserData } = useSelector(
    (state) => state.combinedReducers.auth
  );

  return (
    <div className={classes.container}>
      <h2 className={classes.title}>Account Details</h2>
      <div className={classes.item}>
        <h3 className={classes.label}>Name:</h3>
        <span className={classes.value}>{currentUserData?.name}</span>
      </div>
      <div className={classes.item}>
        <h3 className={classes.label}>Email:</h3>
        <span className={classes.value}>{currentUserData?.email}</span>
      </div>
      <div className={classes.item}>
        <h3 className={classes.label}>Category:</h3>
        <span className={classes.value}>{currentUserData?.category}</span>
      </div>
      {/* <Button type="submit" text="EDIT" /> */}
    </div>
  );
};

export default AccountDetails;

import classes from "./Dashboard.module.css";
import { useSelector } from "react-redux";

import { userNeedsPermission } from "../../reducers/auth.reducer";

const DashboardPage = () => {
  const { currentUser, currentUserData } = useSelector(
    (state) => state.combinedReducers.auth
  );

  const DashComponent = userNeedsPermission(() => (
    <>
      <h1>Dashboard</h1>
    </>
  ));

  return (
    <>
      <div className={classes.container}>
        {currentUserData.category == "admin" ? (
          <DashComponent />
        ) : (
          <h1>You do not have sufficient permissions to view this page</h1>
        )}
      </div>
    </>
  );
};

export default DashboardPage;

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import classes from "./JobForm.module.css";
import Button from "../../Global/Button/Button.js";
import {
  addOrUpdateJob,
  deleteJob,
  toggleJobForm,
  fetchAllJobData,
} from "../../../reducers/job.reducer";
import CustomField from "../../Global/CustomField/CustomField";
import JobFormTyreGrid from "../JobFormTyreGrid/JobFormTyreGrid";
import JobFormAddTyre from "../JobFormAddTyre/JobFormAddTyre";
// import { async } from "@firebase/util";

import { setSelectedJob, getTodaysDate } from "../../../reducers/job.reducer";

const JobForm = () => {
  const dispatch = useDispatch();
  const { showJobTyreForm, selectedJob } = useSelector(
    (state) => state.combinedReducers.jobData
  );

  const [id, setID] = useState(selectedJob?.id || "");
  const [customer, setCustomer] = useState(selectedJob?.customer || "");
  const [reg, setReg] = useState(selectedJob?.reg || "");
  const [contact, setContact] = useState(selectedJob?.contact || "");
  const [inputDate, setInputDate] = useState(
    selectedJob?.date || getTodaysDate()
  );
  const [inputTime, setInputTime] = useState(
    selectedJob?.time || new Date().toLocaleTimeString()
  );
  const [location, setLocation] = useState(selectedJob?.location || "");
  const [price, setPrice] = useState(selectedJob?.price || 0);
  const [cost, setCost] = useState(selectedJob?.cost || 0);
  const [fitter, setFitter] = useState(selectedJob?.fitter || "");
  const [tyres, setTyres] = useState(selectedJob?.tyres || []);
  const [errors, setErrors] = useState({
    customer: false,
    reg: false,
    date: false,
    time: false,
    price: false,
  });

  // Get tyre data on page load
  useEffect(() => {
    let totalCost = 0;
    let totalPrice = 0;
    tyres.forEach((tyre) => {
      totalCost = totalCost + tyre.cost;
      totalPrice = totalPrice + tyre.price;
    });
    setCost(totalCost);
    setPrice(totalPrice);
  }, [tyres]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newJob = {
      id,
      customer,
      reg,
      contact,
      date: inputDate,
      time: inputTime,
      location,
      price,
      fitter,
      tyres,
    };

    // Ensure all fields are filled in
    if (customer && reg && inputDate && inputTime) {
      // Add my new job
      const result = await dispatch(addOrUpdateJob(newJob));

      if (result.payload) {
        // Refresh the data
        dispatch(fetchAllJobData());
        // Hide the job form
        dispatch(toggleJobForm());
        // Clear fields for next job input
        setID("");
        setCustomer("");
        setReg("");
        setContact("");
        setInputDate("");
        setInputTime("");
        setLocation("");
        setPrice("");
        setFitter("");
        setTyres([]);
      }
    } else {
      const stateMap = {
        customer: customer,
        reg: reg,
        date: inputDate,
        time: inputTime,
      };
      let newErrors = [];
      document
        .querySelectorAll('#job-form input[mandatory="true"]')
        .forEach((input) => {
          newErrors = {
            ...newErrors,
            [input.id]: !stateMap[input.id],
          };
        });

      setErrors(newErrors);
    }
  };

  const handleUpdateTyres = async (newTyreList) => {
    const newJob = {
      id,
      customer,
      reg,
      contact,
      date: inputDate,
      time: inputTime,
      location,
      price,
      fitter,
      tyres: newTyreList,
    };

    const result = await dispatch(addOrUpdateJob(newJob));

    if (result.payload) {
      dispatch(setSelectedJob(newJob));
    }
  };

  const handleCancelClick = async () => {
    if (!reg && !id && tyres) {
      if (
        window.confirm(
          "This will not delete the job. The tyre stock has been reduced so please update the stock levels."
        )
      ) {
        dispatch(toggleJobForm());
        dispatch(fetchAllJobData());
      }
    } else {
      dispatch(fetchAllJobData());
      dispatch(toggleJobForm());
    }
  };

  const handleDeleteClick = async () => {
    if (tyres.length > 0) {
      if (window.confirm("This will remove the job are you sure?") == true) {
        const result = await dispatch(deleteJob(selectedJob));
        dispatch(fetchAllJobData());
        dispatch(toggleJobForm());
      }
    } else {
      dispatch(fetchAllJobData());
      dispatch(toggleJobForm());
    }
  };

  return (
    <div className={classes.outer}>
      <div className={classes.container}>
        {showJobTyreForm || !tyres ? (
          <JobFormAddTyre tyres={tyres} updateJobTyres={handleUpdateTyres} />
        ) : (
          <form id="job-form" className={classes.form} onSubmit={handleSubmit}>
            {tyres.length > 0 ? (
              <>
                {selectedJob.id ? (
                  <>
                    <Button
                      text="DELETE JOB"
                      colour="red"
                      type="button"
                      onClick={handleDeleteClick}
                      style={{ width: "100%" }}
                    />
                    <span className={classes.ref}>
                      {"AOK-" + selectedJob.id.slice(-4)}
                    </span>
                  </>
                ) : (
                  ""
                )}
                <div className={classes.doubleField}>
                  <CustomField
                    caption="Customer"
                    type="text"
                    value={customer}
                    onChange={(newValue) => setCustomer(newValue)}
                    mandatory="true"
                    haserror={errors.customer}
                  />
                  <CustomField
                    caption="REG"
                    type="text"
                    value={reg}
                    onChange={(newValue) => setReg(newValue)}
                    mandatory="true"
                    haserror={errors.reg}
                  />
                </div>
                <div className={classes.doubleField}>
                  <CustomField
                    caption="Date"
                    type="date"
                    value={inputDate}
                    onChange={(newValue) => setInputDate(newValue)}
                    mandatory="true"
                    haserror={errors.date}
                  />
                  <CustomField
                    caption="Time"
                    type="time"
                    value={inputTime}
                    onChange={(newValue) => setInputTime(newValue)}
                    mandatory="true"
                    haserror={errors.time}
                  />
                </div>
                <div className={classes.doubleField}>
                  <CustomField
                    caption="Location"
                    type="text"
                    value={location}
                    onChange={(newValue) => setLocation(newValue)}
                  />
                  <CustomField
                    caption="Contact #"
                    type="text"
                    value={contact}
                    onChange={(newValue) => setContact(newValue)}
                  />
                </div>
                <div className={classes.doubleField}>
                  <CustomField
                    caption="Fitter"
                    type="select"
                    value={fitter}
                    onChange={(newValue) => setFitter(newValue)}
                    options={["Oseman", "Liam", "Daz", "Other"]}
                  />
                  {/* <CustomField
                    caption="Price"
                    type="number"
                    value={price}
                    onChange={(newValue) => setPrice(newValue)}
                    haserror={errors.price}
                  /> */}
                  <div className={classes.totals}>
                    <span className={classes.price}>
                      {"Total Price: "}
                      {Intl.NumberFormat("en-GB", {
                        style: "currency",
                        currency: "GBP",
                      }).format(price)}
                    </span>
                    <span className={classes.price}>
                      {"Total Cost: "}
                      {Intl.NumberFormat("en-GB", {
                        style: "currency",
                        currency: "GBP",
                      }).format(cost)}
                    </span>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            <JobFormTyreGrid tyres={tyres} updateJobTyres={handleUpdateTyres} />
            <div className={classes.buttonContainer}>
              <Button
                text="CANCEL"
                colour="red"
                type="button"
                onClick={handleCancelClick}
              />
              <Button text="SAVE" colour="orange" type="submit" />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default JobForm;
